import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { Button, Chip, IconButton, Skeleton, Tooltip } from "@mui/material";
import TableTopFilters from "./TableTopFilters";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import { isSkeleton } from "../../data/store/slices/utilSlice";

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  headCells: any[];
  checkbox: boolean;
  expandable: boolean;
  noOfCol?: number;
}

const EnhancedTableHead = ({
  onSelectAllClick,
  numSelected,
  rowCount,
  headCells,
  checkbox,
  expandable,
  noOfCol,
}: EnhancedTableProps) => {
  return (
    <TableHead>
      <TableRow>
        {checkbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {expandable && <TableCell padding="checkbox" />}

        {headCells &&
          headCells.map((headCell, index) => (
            <TableCell
              key={headCell.id || index}
              padding={headCell.disablePadding ? "none" : "normal"}
              className="text-sm font-semibold"
            >
              {headCell.label}
            </TableCell>
          ))}

        {headCells.length < 1 &&
          Array.from({ length: 5 }).map((e, idx) => (
            <TableCell>
              <Skeleton height={30} />
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

interface ICTable {
  headCells: any[];
  subHeadCells?: any[];
  rows: any[];
  checkbox?: boolean;
  expandable?: boolean;
  tabs?: boolean;
  pagination?: boolean;
  tableFilters?: string[];
  minWidth?: number;
  totalRows?: number;
  noOfCol?: any;
  rowIdentifier?: string;
  filterAction?: (filterIndex: number) => void;
  onRowClick?: (rowId?: number, row?: any) => void;
  refreshDataAPI?: (size: number, page: number) => Promise<void>;
}

const CTable = ({
  headCells,
  subHeadCells,
  rows,
  checkbox = false,
  expandable = false,
  tabs = false,
  pagination = true,
  tableFilters,
  minWidth,
  totalRows = 0,
  noOfCol = 5,
  rowIdentifier = "id",
  filterAction,
  onRowClick,
  refreshDataAPI,
}: ICTable) => {
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const [subRows, setSubRows] = useState<any[]>([]);
  const isSkeletonOn = useSelector(isSkeleton);

  useEffect(() => {
    const updateTable = async () =>
      refreshDataAPI && (await refreshDataAPI(rowsPerPage, page));

    updateTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n[`${rowIdentifier}`]);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage((prev) => newPage);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(page);
  };

  const handleExpandRows = (index: number) => {
    if (expandedRows === index) {
      setExpandedRows(null);
    } else {
      setExpandedRows(index);
    }
    setSubRows(
      rows.filter((e) => e[`${rowIdentifier}`] === index)[0]["installments"]
    );
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <>
      {tabs && (
        <TableTopFilters
          filters={tableFilters}
          filterAction={filterAction}
          setPage={setPage}
          setSize={setRowsPerPage}
        />
      )}
      <div className="bg-[#FBFCFE] rounded w-full">
        <TableContainer>
          <Table sx={{ minWidth: minWidth }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={totalRows}
              headCells={headCells}
              checkbox={checkbox}
              expandable={expandable}
              noOfCol={noOfCol}
            />
            <TableBody>
              {isSkeletonOn
                ? Array.from({ length: 10 }).map((e, idx) => (
                    <TableRow>
                      {Array.from({ length: noOfCol }).map((e, idx) => (
                        <TableCell>
                          <Skeleton height={20} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : rows &&
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row[`${rowIdentifier}`]);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <>
                        <TableRow
                          hover
                          onClick={() => {
                            if (onRowClick)
                              onRowClick(row[`${rowIdentifier}`], row);
                          }} // Removed call to: (event) => handleClick(event, row.id)
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row[`${rowIdentifier}`] || index}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                          className={`${
                            expandedRows === row[`${rowIdentifier}`] &&
                            "shadow-lg bg-slate-100"
                          }`}
                        >
                          {checkbox && (
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                onClick={(event) =>
                                  handleClick(event, row[`${rowIdentifier}`])
                                }
                              />
                            </TableCell>
                          )}
                          {expandable && (
                            <TableCell padding="checkbox">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => {
                                  handleExpandRows(
                                    row[`${rowIdentifier}`] || index
                                  );
                                }}
                              >
                                {expandedRows === row[`${rowIdentifier}`] ||
                                expandedRows === index ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                          )}
                          {headCells &&
                            headCells.map((col, index) => {
                              const value = row[col.id];

                              if (col.id === "action") {
                                return (
                                  <>
                                    <TableCell key={index} className="w-20">
                                      {col.actionButtons(
                                        row[`${rowIdentifier}`]
                                      )}
                                    </TableCell>
                                  </>
                                );
                              } else
                                return (
                                  <TableCell key={index} className="w-20">
                                    {col.badge ? (
                                      <Chip label={value} color="success" />
                                    ) : col.button && value !== null ? (
                                      <a
                                        href={value}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Button
                                          className={`h-10 capitalize`}
                                          variant="text"
                                        >
                                          download
                                        </Button>
                                      </a>
                                    ) : (
                                      <Tooltip
                                        title={value}
                                        sx={{ maxWidth: "20rem" }}
                                      >
                                        <p className="truncate w-40">{value}</p>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                );
                            })}
                        </TableRow>
                        {(expandedRows === row[`${rowIdentifier}`] ||
                          expandedRows === index) && (
                          <TableRow key={index}>
                            <TableCell colSpan={subHeadCells?.length! + 1}>
                              <TableContainer className="border rounded shadow-lg bg-slate-100">
                                <Table aria-labelledby="tableTitle">
                                  <EnhancedTableHead
                                    numSelected={selected.length}
                                    onSelectAllClick={handleSelectAllClick}
                                    rowCount={totalRows}
                                    headCells={subHeadCells || []}
                                    checkbox={false}
                                    expandable={false}
                                  />
                                  <TableBody>
                                    {subRows.map((subRow) => (
                                      <TableRow hover>
                                        {subHeadCells &&
                                          subHeadCells.map((col, index) => {
                                            let value = subRow[col.id];
                                            return (
                                              <>
                                                <TableCell
                                                  key={index}
                                                  className="w-20 relative"
                                                >
                                                  {col.badge ? (
                                                    <Chip
                                                      label={value}
                                                      color="success"
                                                    />
                                                  ) : col.button &&
                                                    value !== null ? (
                                                    <a
                                                      href={value}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      <Button
                                                        className={`h-10 capitalize`}
                                                        variant="text"
                                                      >
                                                        download
                                                      </Button>
                                                    </a>
                                                  ) : (
                                                    <p className="truncate w-40">
                                                      {value}
                                                    </p>
                                                  )}
                                                </TableCell>
                                              </>
                                            );
                                          })}
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </div>
    </>
  );
};

export default CTable;
