import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { editAccuntSchema } from "../../../../data/validations/ValidationScemas";
import { AccountDetail } from "../../../../data/models/types/Account";
import SelectField from "../../../../components/formComponents/fieldComponents/SelectField";
import InputField from "../../../../components/formComponents/fieldComponents/InputField";
import JoyUIDatePickerField from "../../../../components/formComponents/fieldComponents/JoyUIDatePickerField";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { updateAccount } from "../../../../data/api/accountAPI";
import { useDispatch } from "react-redux";
import {
  setLoading,
  setOpenModal,
} from "../../../../data/store/slices/utilSlice";
import notification from "../../../../utils/toast";
dayjs.extend(customParseFormat);

interface IEditAccount {
  accountId: string;
  lender: string;
  borrowerId: string;
  account?: AccountDetail;
  refreshAccount: () => void;
}

interface IformValues {
  status: number;
  loanType: number;
  totalAmount: number;
  balancePrincipleAmount: number;
  balanceAmount: number;
  merchantName: string;
  delinquentDaysOnSubmission: number;
  startDate: any;
  dueDate: any;
}

const status = [
  // { label: "Created", value: 0 },
  // { label: "Pending", value: 1 },
  { label: "In Collection", value: 2 },
  // { label: "Disputed", value: 3 },
  // { label: "Pay Later", value: 4 },
  // { label: "Payment Plan Created", value: 5 },
  // { label: "Payment Plan In Progress", value: 6 },
  // { label: "Payment Plan Failed", value: 18 },
  // { label: "Collected", value: 7 },
  // { label: "Full Settlement", value: 8 },
  // { label: "Partial Settlement", value: 9 },
  // { label: "Closed", value: 10 },
  // { label: "Deleted", value: 11 },
  { label: "Withdrawn", value: 12 },
  // { label: "On Hold", value: 13 },
  { label: "Do not Contact", value: 14 },
  // { label: "Deceased", value: 15 },
  // { label: "Scheduled", value: 16 },
  // { label: "Payment In Progress", value: 17 },
];

const loanType = [
  { label: "Credit Card", value: 1 },
  { label: "Lease", value: 2 },
  { label: "Personal Loan", value: 3 },
  { label: "BNPL", value: 4 },
];

const EditAccount = ({
  account,
  lender,
  accountId,
  borrowerId,
  refreshAccount,
}: IEditAccount) => {
  const dispatch = useDispatch();

  const updateStatus = async (values: IformValues) => {
    try {
      const res: any = await updateAccount({
        lender,
        accountId,
        borrowerId,
        body: {
          ...values,
          startDate: dayjs(values.startDate).format("DD/MM/YYYY"),
          dueDate: dayjs(values.dueDate).format("DD/MM/YYYY"),
        },
      });
      if (res.code === 200) {
        refreshAccount!();

        notification("Account updated.", "success");
        dispatch(setOpenModal({ modalName: "edit_account", openModel: false }));
      } else {
        notification(res.message, "error");
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoading(false));
    }
  };

  return (
    <Formik
      initialValues={{
        status: account?.statusValue!,
        loanType: account?.loanTypeValue!,
        totalAmount: account?.totalAmount!,
        balancePrincipleAmount: account?.balancePrincipleAmount!,
        balanceAmount: account?.balanceAmount!,
        merchantName: account?.merchantName! || "",
        delinquentDaysOnSubmission: account?.delinquentDaysOnSubmission!,
        startDate: dayjs(account?.startDate, "DD/MM/YYYY"),
        dueDate: dayjs(account?.dueDate, "DD/MM/YYYY"),
      }}
      validationSchema={editAccuntSchema}
      onSubmit={async (values: IformValues) => {
        updateStatus(values);
      }}
    >
      {({ errors }) => (
        <Form className="container" id="update_account">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectField
                label="Account Status"
                options={status}
                name="status"
                errorText={errors.status}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectField
                label="Loan Type"
                options={loanType}
                name="loanType"
                errorText={errors.loanType}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                placeholder="Enter amount"
                label="Total Amount"
                name="totalAmount"
                errorText={errors.totalAmount}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                placeholder="Enter Amount"
                label="Balance Amount"
                name="balanceAmount"
                errorText={errors.balanceAmount}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                placeholder="Enter Amount"
                label="Balance Principle Amount"
                name="balancePrincipleAmount"
                errorText={errors.balancePrincipleAmount}
              />
            </Grid>

            <Grid item xs={6}>
              <InputField
                placeholder="Enter name"
                label="Merchant Name"
                name="merchantName"
                errorText={errors.merchantName}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                placeholder="Enter days"
                label="Delinquent Days"
                name="delinquentDaysOnSubmission"
                errorText={errors.delinquentDaysOnSubmission}
              />
            </Grid>
            <Grid item xs={6}>
              <JoyUIDatePickerField
                name="startDate"
                errorText={errors.startDate?.toLocaleString()}
                horizontal={false}
                label="Loan Start Date"
              />
            </Grid>
            <Grid item xs={6}>
              <JoyUIDatePickerField
                name="dueDate"
                errorText={errors.dueDate?.toLocaleString()}
                horizontal={false}
                label="Due Date"
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EditAccount;
